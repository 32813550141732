import { Button, Col, Row, Form, notification, Tag, Modal } from 'antd';
import { useCallback, useContext, useEffect, useState } from 'react';
import { apiCms, apiOms, apiWms } from '../../../../shared/api';
import { AuthContext } from 'shared/contexts/Auth';
import { OrderDetails } from './Components/OrderDetails';
import { OtherDetails } from './Components/OtherDetails';
import { ManualOrderAddressForm } from './Components/ShippingAddress';
import styles from './index.module.scss';
import {
	populateProductItems,
	fetchAddresses,
	IManualOrderFormName,
	createOrder,
	createStoreAddress,
	getStoreAddress,
	IManualOrderTypes,
	getD2rOrderTotalPrice
} from './utils';
import { SHOP_TYPE } from '../../../../constants';
import errorHandler from 'shared/utils/errorHandler';
import { useHistory } from 'react-router-dom';
import { FulfilllmentDetails } from './Components/FulfilllmentDetails';
import { ArrowLeftOutlined } from '@ant-design/icons';
import { Loading } from '../../../../shared/components/Loading';
import { envs } from 'shared/utils/env';
const { appType } = envs;

export const manualOrderRoute = '/orders/new';

export const ManualOrder = () => {
	const isWMSPanel = appType === 'AppWms';
	const history = useHistory();
	const existOrderData = history.location.state;
	const [form] = Form.useForm();
	const [isLoading, setIsLoading] = useState(false);
	const [catalogData, setCatalogData] = useState([]);
	const [storeAddressData, setStoreAddressData] = useState([]);
	const [warehouses, setWarehouses] = useState([]);
	const [isSubmitButtonLoading, setIsSubmitButtonLoading] = useState(false);
	const [showBillingForm, setShowBillingForm] = useState(false);
	const [isD2ROrder, setIsD2ROrder] = useState(existOrderData?.isD2ROrder || false);
	const [isNewStoreAddress, setIsNewStoreAddress] = useState(false);
	const [selectedStoreAddress, setSelectedStoreAddress] = useState(null);
	const [shouldShowSalesmanForm, setShouldShowSalesmanForm] = useState(false);
	const [itemPaymentType, setItemPaymentType] = useState(0);
	const [pricingType, setPricingType] = useState('');
	const [pricingValue, setPricingValue] = useState(0);
	const [selectedItemDetails, setSelectedItemDetails] = useState({});
	const [totalPrice, setTotalPrice] = useState(0);
	const [specialDiscount, setSpecialDiscount] = useState(0);
	const [isModalVisible, setIsModalVisible] = useState(false);
	const [isB2BOrderFlag, setIsB2BOrderFlag] = useState(false);
	const [flagRepeat, setFlagRepeat] = useState(false);
	const {
		authState: { profile, selectedCompanyIds }
	} = useContext(AuthContext);

	const editStoreDetailsSelection = (eventId) => {
		const filterStoreAddress = storeAddressData.filter((storeAddressDataItem) => storeAddressDataItem.id === eventId)?.[0];
		if (!filterStoreAddress) {
			return;
		}

		setSelectedStoreAddress({
			firstName: filterStoreAddress['firstName'],
			lastName: filterStoreAddress['lastName'],
			phone: filterStoreAddress['phone'],
			address1: filterStoreAddress['addressLine1'],
			address2: filterStoreAddress.addressLine2,
			city: filterStoreAddress['city'],
			state: filterStoreAddress['state'],
			zip: filterStoreAddress['pincode'],
			email: filterStoreAddress['email'],
			GSTIN: filterStoreAddress['GSTIN'],
			id: eventId,
			isDistributor: filterStoreAddress['isDistributor']
		});
	};

	useEffect(() => {
		if (existOrderData?.shippingD2RAddressId && existOrderData?.shippingAddress) {
			setSelectedStoreAddress({
				firstName: existOrderData?.shippingAddress?.firstName,
				lastName: existOrderData?.shippingAddress?.lastName,
				phone: existOrderData?.shippingAddress?.phone,
				address1: existOrderData?.shippingAddress?.addressLine1,
				address2: existOrderData?.shippingAddress?.addressLine2,
				city: existOrderData?.shippingAddress?.city,
				state: existOrderData?.shippingAddress?.state,
				zip: existOrderData?.shippingAddress?.pincode,
				email: existOrderData?.shippingAddress?.email,
				GSTIN: existOrderData?.shippingAddress?.GSTIN,
				id: existOrderData?.shippingD2RAddressId
			});
		}
		// existOrderData - data for order when clone and edit selected
		if (existOrderData?.items) {
			setSelectedItemDetails(
				existOrderData.items.reduce((selectedItemObject, dataItem, index) => {
					selectedItemObject[index] = {
						...dataItem,
						id: dataItem.catalogueId,
						quantity: Math.abs(dataItem.quantity),
						price: Math.round(dataItem.finalPrice / dataItem.quantity),
						isFree: Math.round(dataItem.finalPrice / dataItem.quantity) === 0
					};
					return selectedItemObject;
				}, {})
			);
		}
	}, []);

	const editShippingAddress = (updatedAddress) => {
		form.setFieldsValue({
			shippingAddress: {
				...form.getFieldsValue(IManualOrderFormName.SHIPPING_ADDRESS),
				...updatedAddress
			}
		});
	};

	const editStoreAddress = (updatedAddress) => {
		form.setFieldsValue({
			storeAddress: {
				...form.getFieldsValue(IManualOrderFormName.STORE_ADDRESS),
				...updatedAddress
			}
		});
	};

	const editBillingAddress = (updatedAddress) => {
		form.setFieldsValue({
			billingAddress: {
				...form.getFieldsValue(IManualOrderFormName.BILLING_ADDRESS),
				...updatedAddress
			}
		});
	};

	const redirectToOrdersPage = () => history.push('/orders');

	const fetchProducts = async () => {
		try {
			const {
				data: { catalogue }
			} = await apiCms.get(isWMSPanel ? `/catalogue/company/${selectedCompanyIds?.[0]}` : `/catalogue/company/${profile.id}`);

			setCatalogData(catalogue);
		} catch (error) {
			console.log(error);
		}
	};

	const fetchDetails = async () => {
		try {
			setIsLoading(true);
			await fetchProducts();
			// await fetchWarehouses();
		} catch (error) {
			console.log(error);
		} finally {
			setIsLoading(false);
		}
	};

	useEffect(() => {
		if (profile?.id) fetchDetails();
	}, []);

	const handleBillingAddressForm = (event) => {
		setShowBillingForm(!event.target.checked);
	};

	const handleModalConfirm = () => {
		setIsB2BOrderFlag(true);
		setIsModalVisible(false);
	};
	const handleModalCancel = () => {
		setIsB2BOrderFlag(false);
		setIsModalVisible(false);
	};

	const onFinish = async (formValues) => {
		try {
			setIsSubmitButtonLoading(true);
			let {
				items,
				address,
				billingAddress: billing,
				shippingAddress: shipping,
				paymentMethod,
				awb,
				awbPartner,
				shippingLabelUrl,
				invoiceUrl,
				id,
				shippingPrice,
				ewayBill,
				backdate,
				purchaseOrderNumber,
				orderType,
				beatId,
				termsOfPayment,
				duedate,
				pricingType,
				commonPricingValue,
				d2rSalesmanId,
				specialDiscount,
				d2rWarehouseId
			} = formValues;
			if (!items?.length) throw new Error('Please select atleast 1 product');
			items = populateProductItems(items, catalogData, pricingType, commonPricingValue);
			const sumQuantity = items.reduce((sum, item) => item.quantity + sum, 0);

			// ignore b2b flag check for d2r order
			if (!isD2ROrder && sumQuantity > 10 && !isB2BOrderFlag && !flagRepeat) {
				setIsModalVisible(true);
				setFlagRepeat(!flagRepeat);
				return;
			}

			const { shippingAddress, billingAddress, customer } = fetchAddresses(shipping || selectedStoreAddress, billing);

			const formData = {
				data: {
					shopType: formValues.awb?.length ? SHOP_TYPE.MANUAL_FBW : SHOP_TYPE.MANUAL,
					items,
					companyId: isWMSPanel ? selectedCompanyIds?.[0] : profile.id,
					paymentMethod,
					weightUnit: 'g',
					shippingAddress,
					billingAddress,
					customer,
					eventType: 'create',
					awb,
					awbPartner,
					shippingLabelUrl,
					invoiceUrl,
					id,
					shippingPrice,
					ewayBill,
					backdate,
					purchaseOrderNumber,
					b2bOrder: isB2BOrderFlag || orderType === IManualOrderTypes.B2B_ORDER,
					isD2ROrder: orderType === IManualOrderTypes.D2R_ORDER,
					beatId,
					selectedStoreAddressId: orderType === IManualOrderTypes.D2R_ORDER ? selectedStoreAddress?.id : null,
					termsOfPayment,
					dueDate: duedate,
					d2rSalesmanId: orderType === IManualOrderTypes.D2R_ORDER ? d2rSalesmanId : null,
					specialDiscount,
					whId: orderType === IManualOrderTypes.D2R_ORDER ? d2rWarehouseId : null,
					isDistributor: selectedStoreAddress?.isDistributor || false
				}
			};

			const {
				data: { status, message }
			} = await createOrder(formData);

			if (status) {
				notification.success({
					description: 'Order created successfully',
					position: 'topRight'
				});

				// history.push('/orders');
				window.location.replace('/orders');
			} else {
				throw new Error(message);
			}
		} catch (error) {
			errorHandler(error);
		} finally {
			setIsSubmitButtonLoading(false);
		}
	};

	useEffect(() => {
		getStoreAddressList();
		getWarehousesData();
	}, []);

	const getStoreAddressList = async () => {
		const {
			data: { storeAddressData }
		} = await getStoreAddress(isWMSPanel ? selectedCompanyIds?.[0] : '');
		setStoreAddressData(storeAddressData);
	};

	const getWarehousesData = async () => {
		const {
			data: { warehouses }
		} = await apiWms.get(isWMSPanel ? `/company/warehouses?companyId=${selectedCompanyIds?.[0]}` : `/company/warehouses`);
		setWarehouses(warehouses);
	};

	const onSubmitSalesmanForm = async () => {
		try {
			setIsLoading(true);
			const formValues = form.getFieldValue();
			const {
				[IManualOrderFormName.SALESMAN_DETAILS]: { firstName, lastName, email, phone }
			} = formValues;

			const { data } = await apiOms.post('/salesman', { firstName, lastName, email, phone });
			if (data?.status) {
				notification.success({
					description: data?.message,
					position: 'topRight'
				});
			}
		} catch (error) {
			errorHandler(error);
		} finally {
			setShouldShowSalesmanForm(false);
			setIsLoading(false);
		}
	};

	const addNewStoreAddress = async () => {
		const formFieldsValue = form.getFieldsValue();
		const storeAddressDetails = formFieldsValue[IManualOrderFormName.STORE_ADDRESS];
		const { storeName, phone, address1, address2, city, state, zip, email, GSTIN, isDistributor } = storeAddressDetails;

		if (!storeName || !phone || !address1 || !address2 || !city || !state || !zip) {
			notification.error({
				description: 'Please fill all fields in store address',
				position: 'topRight'
			});

			return;
		}

		const storeAddress = {
			GSTIN,
			storeName,
			email,
			address1,
			phone,
			city,
			pincode: zip,
			province: state,
			country: 'India',
			address2,
			company: '',
			latitude: 0,
			longitude: 0,
			countryCode: 'IN',
			provinceCode: '',
			isDistributor: isDistributor
		};

		try {
			const {
				data: { status }
			} = await createStoreAddress(storeAddress);
			if (status) {
				await getStoreAddressList();
				setIsNewStoreAddress(false);
			}
		} catch (error) {
			errorHandler(error);
		}
	};

	const handleCataloguePricing = useCallback(
		async (selectedItems) => {
			const updateProductObject = ({ catalogueId, quantity, isFree, pricingValue: itemPricing }) => {
				const catalog = catalogData.find((catalog) => catalog.id === catalogueId);
				const updatedProductObject = {
					quantity,
					price: catalog?.price,
					gstRate: catalog?.gstPercentage,
					discount: 0,
					pricingType,
					pricingValue: Number(itemPricing) || Number(pricingValue),
					basePrice: catalog?.basePrice ?? 0,
					isFree
				};

				return updatedProductObject;
			};

			const populatedProductItems = selectedItems.map(updateProductObject);

			const itemsFinalPrice = getD2rOrderTotalPrice(populatedProductItems) - (specialDiscount || 0);
			setTotalPrice(itemsFinalPrice);
		},
		[pricingValue, pricingType, catalogData, specialDiscount]
	);

	useEffect(() => {
		handleCataloguePricing(Object.values(selectedItemDetails));
	}, [handleCataloguePricing, selectedItemDetails]);

	if (isLoading) {
		return <Loading loading={true} />;
	}

	return (
		<Row className={`h-100 p-0 ${styles.manualOrder}`}>
			<Col span={4} style={{ display: 'flex', alignItems: 'center', flexDirection: 'column', rowGap: '2rem', padding: '2rem' }}>
				{isD2ROrder && <Tag>Total Price: {totalPrice || 0}</Tag>}
				<Button className={styles.backButton} icon={<ArrowLeftOutlined />} onClick={redirectToOrdersPage}>
					Go back
				</Button>
			</Col>
			<Col span={20} className={styles.outerContainer}>
				<div className={styles.innerContainer}>
					<h3>Create new order</h3>
					<br />
					{isWMSPanel && selectedCompanyIds.length !== 1 ? (
						<h5>Please select at least one company to create order!</h5>
					) : (
						<Form form={form} layout="vertical" onChange={console.log} onFinish={onFinish} scrollToFirstError>
							<OrderDetails
								catalogData={catalogData}
								customStyle={styles.cardStyle}
								existOrderData={existOrderData}
								setD2ROrderFlagInParent={(event) => {
									setIsD2ROrder(Boolean(event.target.value === IManualOrderTypes.D2R_ORDER));
								}}
								isShowTermOfPayment={isD2ROrder}
								{...{
									isNewStoreAddress,
									setIsNewStoreAddress,
									addNewStoreAddress,
									editStoreDetailsSelection,
									storeAddressData,
									editStoreAddress,
									isD2ROrder,
									onSubmitSalesmanForm,
									shouldShowSalesmanForm,
									setShouldShowSalesmanForm,
									itemPaymentType,
									setItemPaymentType,
									setPricingType,
									setPricingValue,
									selectedItemDetails,
									setSelectedItemDetails,
									warehouses
								}}
							/>

							{!isD2ROrder && (
								<>
									<ManualOrderAddressForm
										formName={IManualOrderFormName.SHIPPING_ADDRESS}
										existOrderData={existOrderData}
										customStyle={styles.cardStyle}
										editAddress={editShippingAddress}
										handleBillingAddressForm={handleBillingAddressForm}
										formHeading={IManualOrderFormName.SHIPPING_DETAILS}
									/>

									{showBillingForm && (
										<ManualOrderAddressForm
											formName={IManualOrderFormName.BILLING_ADDRESS}
											existOrderData={existOrderData}
											customStyle={styles.cardStyle}
											editAddress={editBillingAddress}
											formHeading={IManualOrderFormName.BILLING_ADDRESS_HEADING}
										/>
									)}
								</>
							)}

							{/* <PickupDetails warehouses={warehouses} customStyle={styles.cardStyle} /> */}

							<OtherDetails
								existOrderData={existOrderData}
								setSpecialDiscount={setSpecialDiscount}
								customStyle={styles.cardStyle}
								isD2ROrder={isD2ROrder}
							/>

							<FulfilllmentDetails existOrderData={existOrderData} customStyle={styles.cardStyle} />
							<Modal
								title="Convert Order to B2B"
								visible={isModalVisible}
								onOk={handleModalConfirm}
								onCancel={handleModalCancel}
							>
								Do you want to convert the order to B2B (since product units exceeds 10) ?
							</Modal>
							<Button type="primary" size="large" htmlType="submit" loading={isSubmitButtonLoading}>
								Submit
							</Button>
						</Form>
					)}
				</div>
			</Col>
		</Row>
	);
};
